<template>
  <div>
    <navbar title="实名认证" />
    <div class="head">
      <div class="name">
        <img src="../../assets/images/people.png" />
        <div>{{ username }}</div>
      </div>
      <div class="IDnumber">
        <img src="../../assets/images/identity.png" />
        <div>{{ IDnumber }}</div>
      </div>
    </div>
    <div>
      <div class="upload">上传人脸照片</div>
    </div>
    <div class="bottom-img">
      <van-uploader v-model="showImg" max-count="2" :deletable="false" :after-read="afterRead" :preview-full-image="false" :preview-image="false" accept="image/*">
        <template #default v-if="avatarUrl">
          <img :src="avatarUrl" alt="" />
        </template>
      </van-uploader>
    </div>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";

export default {
  name: "Certification",
  data() {
    return {
      username: "",
      IDnumber: "",
      showImg: [],
      avatarUrl: require("../../assets/images/upload_face.png"),
    };
  },
  methods: {
    //上传图片
    afterRead(res) {
      this.showImg = [];
      this.avatarUrl = res.content;
      this.showImg.push(res);
    },
  },
  components: {
    navbar,
  },
};
</script>
<style lang="less" scoped>
.head {
  margin-left: 20px;
  margin-top: 20px;
  .name {
    display: flex;
    font-size: 15px;
    color: #363535;
    img {
      width: 20px;
      height: 20px;
      margin-right: 17px;
    }
  }

  .IDnumber {
    display: flex;
    font-size: 15px;
    color: #363535;
    margin-top: 20px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 17px;
    }
  }
}
.upload {
  width: 335px;
  height: 45px;
  color: #444444;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 25px;
}
.bottom-img {
  width: 335px;
  height: 225px;
  border: 1px dotted #838181;
  margin-left: 20px;
  img {
    width: 335px;
    height: 225px;
  }
}
</style>